<template>
  <ButtonBase v-bind="($attrs, $props)" base-classes="rounded-xl">
    <slot />
  </ButtonBase>
</template>

<script lang="ts">
import props from '~/components/ui/interactive/ButtonBase.vue';

export default {
  props,
};
</script>

<style>
.btn-primary-default {
  background: linear-gradient(180deg, #0061ff 33.33%, #0054dd) !important;
}

.btn-primary-default:hover {
  background: linear-gradient(180deg, #0061ff 33.33%, #0061ff) !important;
}

.btn-halloween {
  background: linear-gradient(180deg, #e76e47 33.33%, #e73800 100%) !important;
}

.btn-halloween:hover {
  background: linear-gradient(180deg, #e76e47 33.33%, #e76e47 100%) !important;
}
</style>
